import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";

const config = {
  projectId: "dci-digital-team",
  apiKey: "AIzaSyAy5JB9gCkgTcR68rcUdgFxu0s34vxrMJk",
  authDomain: "dci-digital-team.firebaseapp.com",
  databaseURL: "https://dci-digital-team.firebaseio.com",
  storageBucket: "dci-digital-team.appspot.com",
};

const firebaseApp = firebase.initializeApp(config);

export const db = firebaseApp.database();

// export const tasksRef = db.ref("behindScenes");

export default firebase;
