<template>
  <div class="flex flex-col min-h-screen">
    <div class="logo">
      <img src="~@/assets/seinfeldjunkielogo.png" />
      <!-- <h1 class="mb-3 text-3xl font-bold logo-text">
      Seinfeld <span>Junkie</span>
    </h1> -->
    </div>

    <div class="mt-4 search-tool">
      <div class="searchbar">
        <label class="block">
          <span class="block mb-1 text-sm text-left text-gray-100"
            >Search All Seinfeld Episodes:</span
          >
          <input
            type="text"
            id="search-term"
            placeholder="Search term"
            v-model="state.searchTerm"
            @click="clearSearch"
            list="title-list"
          />
          <datalist id="title-list">
            <option
              :value="`${item.title}`"
              v-for="item of state.sortedEpisodes"
              :key="item.imdbId"
            ></option>
          </datalist>
        </label>
      </div>
    </div>

    <div
      v-if="state.searchTerm.length < 3"
      class="flex-grow mt-8 text-gray-800"
    >
      <div
        class="inline-block p-4 border-2 yellow-wrapper"
        v-if="state.sortedEpisodes.length"
      >
        <div class="max-w-xs mx-auto bg-white rounded-lg shadow-lg">
          <img
            :src="state.sortedEpisodes[state.randomInt].image"
            alt=""
            class="rounded-t-lg"
          />
          <div class="p-6">
            <h2 class="mb-2 text-xl text-gray-800">Suggested Search Term:</h2>
            <p class="text-xl italic text-gray-700">
              {{
                state.sortedEpisodes[state.randomInt].title.replace("The ", "")
              }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="state.searchTerm.length > 2 && filteredEpisodes.length > 0"
      class="container flex flex-col mx-auto mt-1 w-100 lg:w-4/5"
    >
      <div class="px-4 pt-4 mx-2 mt-6 border-2 yellow-wrapper">
        <!-- card -->
        <div
          v-for="(episode, key) in filteredEpisodes"
          :key="key"
          class="flex flex-col mb-4 overflow-hidden bg-white rounded-lg shadow-xl md:flex-row w-100"
        >
          <!-- media -->
          <div class="w-auto md:w-3/5 poster-box">
            <img
              class="inset-0 object-cover object-center w-full h-full"
              :src="episode.image"
            />
          </div>
          <!-- content -->
          <div class="flex flex-col w-full px-6 py-4 text-gray-800">
            <div class="flex justify-between">
              <h3 class="mb-2 text-lg font-semibold leading-tight truncate">
                {{ episode.title }}
              </h3>
              <h3
                class="font-semibold tracking-wide text-gray-700 uppercase text-md"
              >
                S{{ episode.season }} &bull; E{{ episode.episode }}
              </h3>
            </div>
            <div class="flex justify-between">
              <p>
                <span class="font-bold">Air Date:</span>
                {{ parseInt(episode.releaseDate.substr(5, 2)) }}/{{
                  parseInt(episode.releaseDate.substr(-2))
                }}/{{ episode.releaseDate.substr(0, 4) }}
              </p>
              <p><span class="font-bold">Rating:</span> {{ episode.rating }}</p>
            </div>
            <p class="text-left">
              <span class="font-bold">Key:</span> {{ episode.key }}
            </p>
            <p class="mt-4 text-left">
              {{ episode.plot }}
            </p>
            <div class="flex flex-row justify-center m-3 play-buttons">
              <a
                :href="episode.hulu"
                v-if="episode.hulu"
                target="_blank"
                class="mr-3 w-36"
                ><div class="p-2 text-lg font-bold text-white rounded hulu-btn">
                  Play on Hulu
                </div></a
              >
              <a
                :href="amazonLink(episode.amazon)"
                v-if="episode.amazon"
                target="_blank"
                class="w-36"
                ><img src="~@/assets/amazon-btn.png"
              /></a>
            </div>
          </div>
        </div>
        <!--/ card-->
      </div>
    </div>

    <h2
      class="mt-10 mb-3 text-2xl font-bold text-center text-gray-100 sm:text-left sm:px-10"
    >
      Behind the Scenes Videos
    </h2>
    <div class="grid grid-cols-1 gap-5 px-10 sm:grid-cols-2 md:grid-cols-3">
      <div
        class="relative flex flex-col overflow-hidden bg-white rounded-lg shadow-lg"
        v-for="item of state.videos"
        :key="item.youtube"
        @click="playVideo(item)"
      >
        <div class="overflow-hidden rounded-t-lg">
          <img
            :src="`https://img.youtube.com/vi/${item.youtube}/mqdefault.jpg`"
            class="w-full"
          />
        </div>
        <div class="flex-grow p-2 text-gray-800 text-md video-title">
          {{ item.title }}
        </div>
        <i class="play-icon far fa-play-circle opacity-80"></i>
      </div>
    </div>

    <footer class="px-4 pt-4 pb-8 mt-8 text-gray-100">
      <div>&copy;{{ state.currYear }} SeinfeldJunkie.com</div>
    </footer>
    <the-modal v-if="state.showVideo">
      <div class="relative bg-black video-holder">
        <div class="close-modal" @click="closeVideo">
          <i class="text-2xl fas fa-times-circle"></i>
        </div>
        <iframe
          :src="`https://www.youtube.com/embed/${state.videoId}`"
          frameborder="0"
          allowfullscreen="allowfullscreen"
          class="w-full h-full"
        ></iframe>
      </div>
    </the-modal>
  </div>
</template>

<script>
// import axios from "axios";
import { db } from "./firebase.js";
import { onMounted, reactive, computed } from "vue";
import TheModal from "@/components/TheModal.vue";
export default {
  name: "App",
  components: {},
  setup() {
    const state = reactive({
      episodes: [],
      sortedEpisodes: [],
      searchTerm: "",
      currYear: "2020",
      randomInt: 0,
      showVideo: false,
      videoId: "",
      videos: [],
    });
    const getFirebaseEpisodes = () => {
      return new Promise(function (resolve) {
        const episodesRef = db.ref("seinfeld");
        episodesRef.on("child_added", (snapshot) => {
          state.episodes.push({
            key: snapshot.key,
            ...snapshot.val(),
          });
        });
        resolve(state.episodes);
      });
    };
    const getFirebaseVideos = () => {
      return new Promise(function (resolve) {
        const episodesRef = db.ref("seinfeldBehindScenes");
        episodesRef.on("child_added", (snapshot) => {
          state.videos.push({
            key: snapshot.key,
            ...snapshot.val(),
          });
        });
        resolve(state.videos);
      });
    };
    const filteredEpisodes = computed(() => {
      return state.episodes.filter(
        (episode) =>
          episode.plot.toLowerCase().includes(state.searchTerm.toLowerCase()) ||
          episode.title
            .toLowerCase()
            .includes(state.searchTerm.toLowerCase()) ||
          (episode.script &&
            episode.script
              .toLowerCase()
              .includes(state.searchTerm.toLowerCase()))
      );
    });
    state.sortedEpisodes = computed(() => {
      return state.episodes.slice().sort(dynamicSort("rating"));
    });
    const amazonLink = (link) => {
      return link + "?ref=seinfeldjunki-20";
    };
    const dynamicSort = (property) => {
      var sortOrder = -1;
      if (property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
      }
      return function (a, b) {
        var result =
          a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
        return result * sortOrder;
      };
    };
    const getSuggestion = () => {
      const min = 0;
      const max = 100;
      state.randomInt = Math.floor(Math.random() * (max - min + 1)) + min;
      setInterval(() => {
        state.randomInt = Math.floor(Math.random() * (max - min + 1)) + min;
      }, 30000);
    };
    const playVideo = (video) => {
      state.videoId = video.youtube;
      state.showVideo = true;
    };
    const closeVideo = () => {
      state.videoId = "";
      state.showVideo = false;
    };
    onMounted(async () => {
      await getFirebaseEpisodes();
      await getFirebaseVideos();
      state.currYear = new Date().getFullYear();
      setTimeout(getSuggestion(), 2000);
    });
    return {
      state,
      filteredEpisodes,
      amazonLink,
      TheModal,
      playVideo,
      closeVideo,
    };
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 20px;
}
.search-tool .searchbar {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
}
.search-tool input {
  background-color: #eeeeee;
  border: none;
  border-radius: 3px;
  font-family: inherit;
  padding: 0.5rem 1rem;
  width: 250px;
  outline: none;
  font-size: 14px;
}
.search-tool button {
  background-color: transparent;
  border: none;
  color: #7a7633;
  font-size: 1.5rem;
  margin-left: 10px;
  cursor: pointer;
  outline: none;
}
.search-tool .custom-menu {
  font-size: 1.1rem;
}
.mt-1 {
  margin-top: 1rem;
}
.mb-1 {
  margin-bottom: 1rem;
}
.poster-box {
  max-height: 400px;
  overflow: hidden;
}
.border-yellow {
  border-color: #fccd0c;
}
.video-title {
  background-color: #fccd0c;
}
.yellow-wrapper {
  border-color: #fccd0c;
}
.play-icon {
  position: absolute;
  color: white;
  left: 40%;
  top: 30%;
  font-size: 4rem;
}
.video-holder {
  width: calc(100vw - 6rem);
  height: calc((100vw - 6rem) * 0.5625);
}
.close-modal {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  top: -1.75rem;
  right: -1.75rem;
  color: #e42028;
}
@media only screen and (max-width: 640px) {
  .play-icon {
    left: 45%;
    top: 35%;
  }
}
@media only screen and (max-width: 450px) {
  .play-icon {
    left: 43%;
  }
}
</style>
